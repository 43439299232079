/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { getCsrfToken, getProviders, signIn } from "next-auth/react"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import getConfig from "next/config"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { UserAuth } from "src/types/gloually"
import * as Yup from "yup"
const { publicRuntimeConfig } = getConfig()

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
})

const AUTH_ERROR_MESSAGES: Record<string, string> = {
  token_not_found: "Token not found",
  token_expired:
    "Your token has expired. We'll send you a new token valid for 48 hours.",
  invalid_email: "We couldn't find an account with that email.",
  something_went_wrong: "Something went wrong. Please try again.",
  email_verified: "Your email has been verified. Please sign in.",
}

function Login() {
  const toast = useToast()
  const router = useRouter()
  const { t } = useTranslation("common")

  const { register, handleSubmit, formState, setError } = useForm<UserAuth>({
    resolver: yupResolver(LoginSchema),
  })

  const onSubmit = async (values: UserAuth) => {
    try {
      await signIn("login", {
        ...values,
        callbackUrl: `${publicRuntimeConfig.GLOUALLY_BASE_URL}`,
      })
    } catch (error) {
      if (error instanceof Error) {
        setError("email", {
          type: "manual",
          message:
            AUTH_ERROR_MESSAGES[error.message] ||
            "Something went wrong. Please try again.",
        })
      }
    }
  }

  useEffect(() => {
    if (Object.keys(router.query).length > 0) {
      toast({
        status: router.query.error ? "error" : "success",
        title: router.query.error ? "Oops" : "Everything's setup!",
        description:
          AUTH_ERROR_MESSAGES[
            (router.query.error || router.query.success) as string
          ] || "Something went wrong. Please try again.",
        duration: 5000,
        position: "top-right",
      })
    }
  }, [router.query, toast])

  return (
    <>
      <Stack>
        <Heading as="h1" fontSize="3xl" fontWeight={500}>
          {t("login.title")}
        </Heading>
        <Text color="surfaceText">
          {t("login.need-account")}
          <NextLink href="/signup" passHref>
            <Link as="span" color="teal.400">
              {t("login.sign-up")}
            </Link>
          </NextLink>
        </Text>
      </Stack>
      <Stack as="form" mt={4} spacing={4} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <FormLabel>{t("login.e-mail")}</FormLabel>
          <Input variant="filled" {...register("email")} />
          {formState.errors.email && (
            <Text fontSize="sm">{formState.errors.email.message}</Text>
          )}
        </Box>
        <Box>
          <FormLabel>{t("login.password")}</FormLabel>
          <Input variant="filled" type="password" {...register("password")} />
          {formState.errors.password && (
            <Text fontSize="sm">{formState.errors.password.message}</Text>
          )}
        </Box>

        <NextLink href="/reset-password">
          <Link as="span" fontSize="sm">
            {t("login.forgot")}
          </Link>
        </NextLink>
        <Box>
          <Button
            isLoading={formState.isSubmitting}
            type="submit"
            mt={4}
            colorScheme="teal"
            isDisabled={formState.isDirty && !formState.isValid}
          >
            {t("login.title")}
          </Button>
        </Box>
      </Stack>
    </>
  )
}

export async function getServerSideProps(context: any) {
  const providers = await getProviders()
  return {
    props: {
      csrfToken: await getCsrfToken(context),
      providers,
      ...(await serverSideTranslations(context.locale || "en", ["common"])),
    },
  }
}

Login.layout = "AuthLayout"

export default Login
